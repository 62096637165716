import React from 'react';
import { Route, Routes } from 'react-router-dom';
import FrontPage from '../../pages/front-page/FrontPage';
import Article from '../../pages/article/Article';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route path="/*" element={<FrontPage />} />
      <Route path="/howto" element={<Article />} />
    </Routes>
  );
};

export default AppRoutes;
