import React, { useEffect, useRef, useState } from 'react';
import './FrontPage.scss';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { useForm } from 'react-hook-form';
import shape from '../../assets/images/curve-top.svg';
import howItWork from '../../assets/images/how-work.png';
import phone from '../../assets/images/phone.png';
import hand from '../../assets/images/hand.png';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { addToNewsletter } from './services/FrontPageService';
import ReCAPTCHA from 'react-google-recaptcha';
import Hamburger from '../../shared/components/hamburger/Hamburger';
import { confirmDialog, ConfirmDialog } from 'primereact/confirmdialog';
import { NewsletterData } from './models/NewsletterData';
import { useTranslation } from 'react-i18next';

const SECTIONS = ['about', 'why-choose-us', 'how-it-works', 'join-to-beta', 'faq'];

const FrontPage: React.FC = () => {
  const [activeSection, setActiveSection] = useState<string>('');
  const [isMenuFixed, setIsMenuFixed] = useState<boolean>(false);
  const [openMobileMenu, setMobileMenu] = useState<boolean>(false);
  const [apiErrorTop, setApiErrorTop] = useState<string | null>(null);
  const [apiErrorBottom, setApiErrorBottom] = useState<string | null>(null);
  const {
    register: registerTop,
    handleSubmit: handleSubmitTop,
    formState: { errors: errorsTop },
  } = useForm();
  const {
    register: registerBottom,
    handleSubmit: handleSubmitBottom,
    formState: { errors: errorsBottom },
  } = useForm();
  const { t } = useTranslation();

  // const SECTIONS_MAP = new Map<string, string>([
  //   ['about', t('about')],
  //   ['why-choose-us', t('whyChooseUs')],
  //   ['how-it-works', t('howItWorks')],
  //   ['join-to-beta', t('joinToBeta')],
  //   ['faq', 'FAQ'],
  // ]);

  const sectionRefs = useRef<HTMLElement[]>([]);
  const menuRef = useRef<HTMLDivElement | null>(null);
  const recaptchaRef = useRef<ReCAPTCHA | null>(null);

  useEffect(() => {
    const handleScroll = () => {
      if (menuRef.current) {
        const { top } = menuRef.current.getBoundingClientRect();
        setIsMenuFixed(top <= -30);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setActiveSection(entry.target.id);
            window.history.replaceState(null, '', `#${entry.target.id}`);
          }
        });
      },
      { threshold: 0.6 }
    );

    sectionRefs.current.forEach((section) => {
      if (section) {
        observer.observe(section);
      }
    });

    return () => {
      sectionRefs.current.forEach((section) => {
        if (section) {
          observer.unobserve(section);
        }
      });
    };
  }, []);

  const transitionOpt = {
    transition: {
      timeout: 0,
    },
  };
  //
  // const prepareMenu = () => {
  //   {
  //     return SECTIONS.map((section) => (
  //       <a
  //         key={section}
  //         href={`#${section}`}
  //         onClick={() => setMobileMenu(false)}
  //         className={activeSection === section ? 'active' : ''}
  //       >
  //         {SECTIONS_MAP.get(section)}
  //       </a>
  //     ));
  //   }
  // };

  const successDialog = () => {
    confirmDialog({
      message: t('emailSuccessfullySaved'),
      header: t('dataSaved'),
      defaultFocus: 'accept',
      acceptLabel: 'OK',
      accept: () => window.location.reload(),
    });
  };

  const onSubmit = async (formData: any, isTop: boolean) => {
    const recaptchaText = t('completedCaptcha');
    if (!recaptchaRef.current) {
      if (isTop) {
        setApiErrorTop(recaptchaText);
      } else {
        setApiErrorBottom(recaptchaText);
      }
      return;
    }

    const token = await recaptchaRef.current?.executeAsync();
    const data = {
      email: formData.email,
      recaptchaToken: token,
    } as NewsletterData;

    await addToNewsletter(data)
      .then(() => successDialog())
      .catch((err) => {
        if (isTop) {
          setApiErrorTop(err.response?.data.message);
        } else {
          setApiErrorBottom(err.response?.data.message);
        }
      });
  };

  const clearApiErrorTop = () => setApiErrorTop(null);
  const clearApiErrorBottom = () => setApiErrorBottom(null);

  return (
    <>
      <ConfirmDialog className="dialog-box dialog-box--accept" />
      <div className={`menu ${isMenuFixed ? 'menu--fixed' : ''}`}>
        <div className="container">
          <Hamburger isActive={openMobileMenu} onClick={() => setMobileMenu(!openMobileMenu)} />
          <div className="logo-box">
            <div className="logo-box__logo" />
          </div>
          <div>
            <a href="/howto">{t('howItWorks')}?</a>
            {/*<div className="menu__container">{prepareMenu()}</div>*/}
          </div>
        </div>
      </div>
      <header className="header" ref={(el) => (sectionRefs.current[1] = el!)}>
        <div className="container" ref={menuRef}>
          <div className="header__title-box title-box title-box--center">
            <h1>{t('elevateYourBrandVisibility')}</h1>
            <h3>{t('elevateYourBrandVisibilityDesc')}</h3>
          </div>
          <div className="header__form-box">
            <form onSubmit={handleSubmitTop((data) => onSubmit(data, true))}>
              <InputText
                type="text"
                {...registerTop('email', { required: t('emailIsRequired') })}
                className={errorsTop.email || apiErrorTop ? 'invalid' : ''}
                placeholder={t('yourEmailAddress')}
                onInput={clearApiErrorTop}
              />
              {errorsTop.email && <span>{errorsTop.email.message as string}</span>}
              {apiErrorTop && <span>{apiErrorTop}</span>}
              <Button label={t('getEarlyAccess')} type="submit" />
            </form>
          </div>
        </div>
        <div className="header__shape">
          <img src={shape} alt="" />
        </div>
      </header>
      <section className="mobile-menu" style={{ display: openMobileMenu ? 'block' : 'none' }}>
        <div className="mobile-menu__container">
          <Hamburger isActive={openMobileMenu} onClick={() => setMobileMenu(!openMobileMenu)} />
        </div>
        <a href="/howto">{t('howItWorks')}?</a>

        {/*<div className="container">{prepareMenu()}</div>*/}
      </section>
      {/*<section className="what-is" id="about" ref={(el) => (sectionRefs.current[2] = el!)}>*/}
      {/*  <div className="container what-is__container">*/}
      {/*    <div className="what-is__image">*/}
      {/*      <img src={howItWork} alt="" />*/}
      {/*    </div>*/}
      {/*    <div className="what-is__text">*/}
      {/*      <div className="title-box">*/}
      {/*        <h2>{t('whatIsChat')}</h2>*/}
      {/*      </div>*/}
      {/*      <h5>{t('whatIsChatDesc')}</h5>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<section className="key-features" id="why-choose-us" ref={(el) => (sectionRefs.current[3] = el!)}>*/}
      {/*  <div className="container">*/}
      {/*    <div className="title-box title-box--center">*/}
      {/*      <h2>{t('whyChoseUp')}</h2>*/}
      {/*    </div>*/}
      {/*    <div className="key-features__container">*/}
      {/*      <div className="key-features__features">*/}
      {/*        <div className="key-features__left-panel">*/}
      {/*          <div className="key-features__item">*/}
      {/*            <div>*/}
      {/*              <span className="key-features__tag">*/}
      {/*                <i className="material-symbols-outlined">monitoring</i>*/}
      {/*              </span>*/}
      {/*            </div>*/}
      {/*            <div>*/}
      {/*              <h4>{t('automaticPromptMonitoring')}</h4>*/}
      {/*              <p>{t('automaticPromptMonitoringDesc')}</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="key-features__item">*/}
      {/*            <div>*/}
      {/*              <span className="key-features__tag">*/}
      {/*                <i className="material-symbols-outlined">analytics</i>*/}
      {/*              </span>*/}
      {/*            </div>*/}
      {/*            <div>*/}
      {/*              <h4>{t('brandMentionsAnalysis')}</h4>*/}
      {/*              <p>{t('brandMentionsAnalysisDesc')}</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div className="key-features__right-panel">*/}
      {/*          <div className="key-features__item">*/}
      {/*            <div>*/}
      {/*              <span className="key-features__tag">*/}
      {/*                <i className="material-symbols-outlined">campaign</i>*/}
      {/*              </span>*/}
      {/*            </div>*/}
      {/*            <div>*/}
      {/*              <h4>{t('shareOfVoiceTracking')}</h4>*/}
      {/*              <p>{t('shareOfVoiceTrackingDesc')}</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*          <div className="key-features__item">*/}
      {/*            <div>*/}
      {/*              <span className="key-features__tag">*/}
      {/*                <i className="material-symbols-outlined">search_insights</i>*/}
      {/*              </span>*/}
      {/*            </div>*/}
      {/*            <div>*/}
      {/*              <h4>{t('competitorVisibilityInsights')}</h4>*/}
      {/*              <p>{t('competitorVisibilityInsightsDesc')}</p>*/}
      {/*            </div>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="key-features__image">*/}
      {/*        <img src={phone} alt="" />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<section className="how-it-works" id="how-it-works" ref={(el) => (sectionRefs.current[4] = el!)}>*/}
      {/*  <div className="container">*/}
      {/*    <div className="title-box title-box--center">*/}
      {/*      <h2>{t('howItWorks')}</h2>*/}
      {/*    </div>*/}
      {/*    <div className="how-it-works__panel">*/}
      {/*      <div className="how-it-works__step-box">*/}
      {/*        <div className="how-it-works__steps">*/}
      {/*          <div className="how-it-works__tag how-it-works__tag--green">*/}
      {/*            <i className="material-symbols-outlined">monitoring</i>*/}
      {/*            <span className="indicator">1</span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div>*/}
      {/*          <h5>{t('setUpMonitoring')}</h5>*/}
      {/*          <p>{t('setUpMonitoringDesc')}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="how-it-works__step-box">*/}
      {/*        <div className="how-it-works__steps">*/}
      {/*          <div className="how-it-works__tag how-it-works__tag--red">*/}
      {/*            <i className="material-symbols-outlined">discover_tune</i>*/}
      {/*            <span className="indicator">2</span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div>*/}
      {/*          <h5>{t('analyzeData')}</h5>*/}
      {/*          <p>{t('analyzeDataDesc')}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*      <div className="how-it-works__step-box">*/}
      {/*        <div className="how-it-works__steps">*/}
      {/*          <div className="how-it-works__tag how-it-works__tag--blue">*/}
      {/*            <i className="material-symbols-outlined">search_insights</i>*/}
      {/*            <span className="indicator">3</span>*/}
      {/*          </div>*/}
      {/*        </div>*/}
      {/*        <div>*/}
      {/*          <h5>{t('optimizeYourStrategy')}</h5>*/}
      {/*          <p>{t('optimizeYourStrategyDesc')}</p>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<section className="join-to-beta" id="join-to-beta" ref={(el) => (sectionRefs.current[5] = el!)}>*/}
      {/*  <div className="container join-to-beta__container">*/}
      {/*    <div className="title-box title-box--center">*/}
      {/*      <h2>{t('readyToTakeControl')}</h2>*/}
      {/*      <h5>{t('readyToTakeControlDesc')}</h5>*/}
      {/*    </div>*/}
      {/*    <form onSubmit={handleSubmitBottom((data) => onSubmit(data, false))}>*/}
      {/*      <InputText*/}
      {/*        type="text"*/}
      {/*        {...registerBottom('email', { required: t('emailIsRequired') })}*/}
      {/*        className={errorsBottom.email || apiErrorBottom ? 'invalid' : ''}*/}
      {/*        placeholder={t('yourEmailAddress')}*/}
      {/*        onInput={clearApiErrorBottom}*/}
      {/*      />*/}
      {/*      {errorsBottom.email && <span>{errorsBottom.email.message as string}</span>}*/}
      {/*      {apiErrorBottom && <span>{apiErrorBottom}</span>}*/}
      {/*      <Button label={t('getEarlyAccess')} />*/}
      {/*    </form>*/}
      {/*  </div>*/}
      {/*</section>*/}
      {/*<section className="faq" id="faq" ref={(el) => (sectionRefs.current[6] = el!)}>*/}
      {/*  <div className="container">*/}
      {/*    <div className="faq__box">*/}
      {/*      <div className="title-box">*/}
      {/*        <h2>{t('frequentlyAskedQueries')}</h2>*/}
      {/*        <p>{t('frequentlyAskedQueriesDesc')}</p>*/}
      {/*      </div>*/}
      {/*      <Accordion multiple={true} className="accordion">*/}
      {/*        <AccordionTab className="accordion__row" header={t('firstQuestion')} pt={transitionOpt}>*/}
      {/*          <p>{t('firstQuestionDesc')}</p>*/}
      {/*        </AccordionTab>*/}
      {/*        <AccordionTab className="accordion__row" header={t('secondQuestion')} pt={transitionOpt}>*/}
      {/*          <p>{t('secondQuestionDesc')}</p>*/}
      {/*        </AccordionTab>*/}
      {/*        <AccordionTab className="accordion__row" header={t('thirdQuestion')} pt={transitionOpt}>*/}
      {/*          <p>{t('thirdQuestionDesc')}</p>*/}
      {/*        </AccordionTab>*/}
      {/*      </Accordion>*/}
      {/*    </div>*/}
      {/*    <div className="faq__image">*/}
      {/*      <img src={hand} alt="" />*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</section>*/}
      <footer>
        <div className="container">
          <div className="footer__box">
            <div className="footer__col">
              {/*<ul className="footer__menu">*/}
              {/*  {SECTIONS.map((section) => (*/}
              {/*    <li key={section} className="menu-item">*/}
              {/*      <a href={`#${section}`}>{SECTIONS_MAP.get(section)}</a>*/}
              {/*    </li>*/}
              {/*  ))}*/}
              {/*</ul>*/}
              <div className="footer__copyright">© 2024 All rights reserved by chatBEAT</div>
            </div>
            <div className="footer__col footer__col--social">
              <a href="https://facebook.com/">
                <i />
              </a>
              <a href="https://facebook.com/">
                <i />
              </a>
              <a href="https://facebook.com/">
                <i />
              </a>
            </div>
            <div className="footer__col">
              <div className="logo-box">
                <div className="logo-box__logo" />
              </div>
            </div>
          </div>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY || 'sample'}
          size="invisible"
        />
      </footer>
    </>
  );
};

export default FrontPage;
